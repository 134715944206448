import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

import { SecondaryHeading } from '../components/reusables/textElements';
import { breakpoints } from '../lib/constants';
import AppChrome from '../components/AppChrome';
import ArticlePreview from '../components/latest/ArticlePreview';
import Content from '../components/article/Content';
import ContentWrapper from '../components/reusables/ContentWrapper';
import Meta from '../components/article/Meta';
import SectionTitle from '../components/reusables/SectionTitle';
import TopNav from '../components/TopNav';

const Container = styled.article({
  display: 'flex',
  justifyContent: 'space-between',
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'block',
  },
});

const Articles = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  columnGap: 20,
  rowGap: 30,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    gridTemplateColumns: '1fr',
  },
});

const Heading = styled.div({
  textAlign: 'center',
  maxWidth: 900,
  margin: '0 auto',
});


const SubHeading = styled.div({
  fontSize: 14,
  textTransform: 'uppercase',
  margin: '20px 0 80px',
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    marginBottom: 40,
  },
});

const numOfRelatedArticles = 3;

function BlogPostTemplate({ data }) {
  const {
    id,
    title,
    publishDate,
    longPublishDate,
    body,
    image,
    author,
    category: { title: categoryTitle, slug: categorySlug },
  } = data.contentfulArticle;

  const articles = data.allContentfulArticle.edges
    .filter(({ node }) => node.category.slug === categorySlug && node.id !== id)
    .map(({ node }) => node).slice(0, numOfRelatedArticles);

  if (articles.length < numOfRelatedArticles) {
    // fill with latest articles
    articles.push(...data.allContentfulArticle.edges
      .filter(({ node }) => node.id !== id)
      .map(({ node }) => node).slice(0, numOfRelatedArticles - articles.length));
  }

  const authorName = author ? author.name : 'Beteendelabbet';

  return (
    <AppChrome
      title={title}
      og={{
        image: image.sizes.src,
        title,
        description: body.childMarkdownRemark.plainText,
        type: 'article',
      }}
    >
      <TopNav />
      <ContentWrapper>
        <Heading>
          <SecondaryHeading>
            {title}
          </SecondaryHeading>
          <SubHeading>{longPublishDate} - av {authorName}</SubHeading>
        </Heading>
        <Img alt={title} sizes={image.sizes} />
      </ContentWrapper>
      <ContentWrapper>
        <Container>
          <Meta {...{ publishDate, authorName, categoryTitle, categorySlug }} />
          <Content {...{ body }} />
          <div />
        </Container>
      </ContentWrapper>
      <ContentWrapper>
        <SectionTitle bold>Relaterade inlägg</SectionTitle>
        <Articles>
          {articles.map(node => {
            return <ArticlePreview {...node} key={node.id} />;
          })}
        </Articles>
      </ContentWrapper>
    </AppChrome>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      id
      slug
      publishDate(formatString: "DD MMMM YYYY", locale: "sv")
      longPublishDate: publishDate(formatString: "D MMMM YYYY", locale: "sv")
      author {
        name
      }
      category {
        title
        slug
      }
      image {
        sizes(maxWidth: 2360, background: "rgb:000000") {
          ...GatsbyContentfulSizes_withWebp
        }
      }
      body {
        childMarkdownRemark {
          html
          plainText
        }
      }
    }
    allContentfulArticle(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "DD MMMM YYYY", locale: "sv")
          category {
            title
            slug
          }
          author {
            name
          }
          image {
            sizes(maxWidth: 700, maxHeight: 392, resizingBehavior: FILL) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          description
        }
      }
    }
  }
`;
