import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { Paragraph3 } from '../reusables/textElements';
import { breakpoints } from '../../lib/constants';

const Container = styled.div({
  marginBottom: 30,
  marginRight: 20,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    marginTop: 10,
    marginBottom: 50,
  },
});

const MetaSection = styled.div({
  marginBottom: 10,
});

function Meta({ publishDate, authorName, categoryTitle, categorySlug }) {
  return (
    <Container>
      <MetaSection>
        <Paragraph3 isBold>Datum</Paragraph3>
        <Paragraph3>{publishDate}</Paragraph3>
      </MetaSection>
      <MetaSection>
        <Paragraph3 isBold>Skrivet av</Paragraph3>
        <Paragraph3>{authorName}</Paragraph3>
      </MetaSection>
      <MetaSection>
        <Paragraph3 isBold>Kategori</Paragraph3>
        <Paragraph3>
          <Link to={`/${categorySlug}`}>{categoryTitle}</Link>
        </Paragraph3>
      </MetaSection>
    </Container>
  );
}

export default Meta;
