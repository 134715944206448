import React from 'react';
import styled from 'styled-components';

const Container = styled.div({
  maxWidth: 800,
});
const Body = styled.div({
  marginTop: -16,
  img: {
    width: '100%',
  },
  'h2,h3,h4,h5': {
    margin: '30px 0 15px',
  },
});

function Content({ title, body }) {
  return (
    <Container>
      <Body
        dangerouslySetInnerHTML={{
          __html: body.childMarkdownRemark.html,
        }}
      />
    </Container>
  );
}

export default Content;
